@font-face {
  font-family: 'FjallaOne';
  src: url(./fonts/FjallaOne.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 15px;
}

body {
  font-family: 'FjallaOne', sans-serif;
}
li {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1 {
  font-size: 28px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 14px;
}

button {
  font-family: 'FjallaOne', sans-serif;
  cursor: pointer;
  outline: 0;
  transition-duration: 0.3s;
  transition-property: transform;
  transform: translateZ(0);
  animation-duration: 0.8s;
  animation-delay: 0.1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
button:hover {
  animation-name: hover;
}
button:hover:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  transition-duration: 0.3s;
  transition-property: transform, opacity;
  transform: translateY(6px);
  animation-name: hover-shadow;
  animation-duration: 1.5s;
  animation-delay: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes hover {
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(-5px);
  }
}
@keyframes hover-shadow {
  0% {
    transform: translateY(5px);
    opacity: 0.4;
  }
  50% {
    transform: translateY(3px);
    opacity: 1;
  }
  100% {
    transform: translateY(5px);
    opacity: 0.4;
  }
}
.active_mobilemenu {
  position: absolute;
  background: #ff8c00;
  width: 100%;
  left: 0;
  top: 60px;
  border-bottom: 3px solid rgba(255, 255, 255, 0.6);
  display: block !important;
  padding: 20px;
}
